$(document).ready(function() {
    
    // if ($('#calendarFrom')) {
    //   $('#calendarFrom').datepicker({
    //     dateFormat : 'yy-mm-dd'
    //   });
    // }
    // if ($('#calendarTo')) {
    //   $('#calendarTo').datepicker({
    //     dateFormat : 'yy-mm-dd'
    //   });
    // }
  
    // if ($('#filter')) {
    //   $('#filter').on('click', function () {
    //     $('.news__calendar').toggleClass('hidden--mobile');
    //   })
    // }
  })