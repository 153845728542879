$(document).ready(function () {
  /**
   * Base
   */
  // Canvas
  const canvas = document.querySelector(".canvas");

  if (!canvas) {
    return;
  }

  // Scene
  const scene = new THREE.Scene();

  // Texture
  const textureLoader = new THREE.TextureLoader();
  const earthMap = textureLoader.load(`${templateUrl}/img/earth-comp.jpg`);
  const particleTexture = textureLoader.load(`${templateUrl}/img/star.png`);
  earthMap.magFilter = THREE.NearestFilter;

  const material = new THREE.MeshStandardMaterial({
    map: earthMap,
  });

  material.bumpMap = textureLoader.load(`${templateUrl}/img/earthramp.jpg`);
  material.bumpScale = 30;

  // objects
  const objectsDistance = 10;

  const geometry = new THREE.SphereGeometry(1.115, 32, 16);
  const sphere = new THREE.Mesh(geometry, material);
  sphere.position.x = 1.2;
  
  THREE.DefaultLoadingManager.onLoad = function ( ) {
    // $('#loader').css('opacity', 0);
    setTimeout(() => {
      // $('#loader').hide();
      if ($(".site.front").length) {
        scene.add(sphere);
      }
    }, 500);
  };

  // Particles
  const particlesCount = 500;
  const positions = new Float32Array(particlesCount * 3);
  const colors = [];
  const color = new THREE.Color();

  for (let i = 0; i < particlesCount; i++) {
    positions[i * 3] = (Math.random() - 0.5) * 10;
    positions[i * 3 + 1] =
      objectsDistance * 0.5 - Math.random() * objectsDistance * 10;
    positions[i * 3 + 2] = (Math.random() - 0.9) * 10;

    const r = Math.random() * (1 - 0.9) + 0.9;
    const g = Math.random() * (1 - 0.9) + 0.9;
    const b = Math.random() * (1 - 0.7) + 0.7;

    color.setRGB(r, g, b, THREE.SRGBColorSpace);

    colors.push(color.r, color.g, color.b);
  }

  const particlesGeometry = new THREE.BufferGeometry();
  particlesGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(positions, 3)
  );
  particlesGeometry.setAttribute(
    "color",
    new THREE.Float32BufferAttribute(colors, 3)
  );

  // Material
  const particlesMaterial = new THREE.PointsMaterial({
    map: particleTexture,
    transparent: true,
    alphaMap: particleTexture,
    size: 0.45,
    sizeAttenuation: true,
    vertexColors: true,
  });

  // Points
  const particles = new THREE.Points(particlesGeometry, particlesMaterial);
  scene.add(particles);

  // Lights

  const directionalLight = new THREE.DirectionalLight("#ffffff", 5);
  directionalLight.position.set(1, 1, 2);
  scene.add(directionalLight);

  /**
   * Sizes
   */
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  /**
   * Camera
   */
  // Group
  const cameraGroup = new THREE.Group();
  scene.add(cameraGroup);

  // Base camera
  const camera = new THREE.PerspectiveCamera(
    35,
    sizes.width / sizes.height,
    0.1,
    100
  );
  camera.position.z = 6;
  cameraGroup.add(camera);

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Scroll
  let scrollY = window.scrollY;
  let currentSection = 0;

  window.addEventListener("scroll", () => {
    scrollY = window.scrollY;
    const newSection = Math.round(scrollY / sizes.height);
    if (newSection != currentSection) {
      currentSection = newSection;
    }
  });

  // Cursor
  const cursor = {};
  cursor.x = 0;
  cursor.y = 0;

  window.addEventListener("mousemove", (e) => {
    cursor.x = e.clientX / sizes.width - 0.5;
    cursor.y = e.clientY / sizes.height - 0.5;
  });

  /**
   * Animate
   */
  const clock = new THREE.Clock();
  let previousTime = 0;

  const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Animate camera
    camera.position.y = (-scrollY / sizes.height) * objectsDistance;

    const parallaxX = cursor.x * 0.5;
    const parallaxY = -cursor.y * 0.5;
    cameraGroup.position.x +=
      (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
    cameraGroup.position.y +=
      (parallaxY - cameraGroup.position.y) * 5 * deltaTime;

    if ($(".site.front").length) {
      sphere.rotation.y += deltaTime * 0.1;
    }

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };

  tick();
});
