$(document).ready(function () {  
  const frontPage = $(".site.front");

  let currentpage = 1;
  let scrollElements;

  var animatingup = false;
  var animatingdown = false;

  if ($('#btnToTop')) {
    $('#btnToTop').on('click', function(e) {
      currentpage = 1;
      setTimeout(() => {
        scrollToCurrent();
        if (frontPage.length) {
          const menuItems = $("span[data-menu-item]");
          switchMenuItem(menuItems);
        }
      }, 200);
    })
  }

  window.onscroll = function() {
    scrollFunction()
  };

  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      $('#btnToTop').show();
    } else {
      $('#btnToTop').hide();
    }
  }

  if (document.location.hash) {
    currentpage = parseInt(document.location.hash.replace("#", ""));
  }

  if (frontPage.length) {
    frontPageScroll();
  }

  // Scroll on front page
  function frontPageScroll() {
    const menuItems = $("span[data-menu-item]");
    const menuRange = document.querySelector("#menu-range");
    const pages = menuItems.length ?? 7;

    scrollElements = document.querySelectorAll("section[name]");
    $(`span[data-menu-item=${currentpage}]`).addClass("active");
    $(`#menu-range`).val(currentpage);

    var nextpage = currentpage + 1;
    if (nextpage > pages) {
      nextpage = pages;
    }
    var prevpage = currentpage - 1;
    if (prevpage < 1) {
      prevpage = 1;
    }

    $(document).ready(function () {
      scrollToCurrent();
    });

    window.onresize = function () {
      scrollToCurrent();
    };

    $(window).on("scroll", scrollToSection);

    function scrollToSection() {
      if ($(window).width() >= 1024) {   
        if (animatingup == true) {
          return;
        }
        if (animatingdown == true) {
          return;
        }

        nextpage = currentpage + 1;
        if (nextpage > pages) {
          nextpage = pages;
        }
        prevpage = currentpage - 1;
        if (prevpage < 1) {
          prevpage = 1;
        }

        if (animatingup == false) {
          if (
            $(window).scrollTop() + $(window).height() >=
            $("#page-" + nextpage).offset().top + 50
          ) {
            if (nextpage > currentpage) {
              var p2 = $("#page-" + nextpage); // find section on page
              var pageheight = p2.position().top; // get its height
              animatingdown = true; // activate animation
              $("html, body").animate(
                { scrollTop: pageheight },
                300,
                function () {
                  currentpage = nextpage;
                  animatingdown = false;
                  switchMenuItem(menuItems);
                }
              );
              return;
            }
          }
        }

        if (animatingdown == false) {
          if (
            $(window).scrollTop() <=
            $("#page-" + currentpage).offset().top - 50
          ) {
            if (prevpage < currentpage) {
              var p2 = $("#page-" + currentpage);
              var pageheight = p2.position().top - $(window).height();
              animatingup = true;
              $("html, body").animate(
                { scrollTop: pageheight },
                300,
                function () {
                  currentpage = prevpage;
                  animatingup = false;
                  switchMenuItem(menuItems);
                }
              );
              return;
            }
          }
        }
      }
    }

    $(`span[data-menu-item=${currentpage}]`).addClass("active");
    $(`#menu-range`).val(currentpage);

    menuItems.on("click", function (e) {
      e.preventDefault();
      if (e.target.closest("span")) {
        currentpage = parseInt(
          e.target.closest("span").getAttribute("data-menu-item")
        );
        scrollToCurrent();
        switchMenuItem(menuItems);
      }
    });

    menuRange.addEventListener("input", (e) => {
      currentpage = e.target.value;
      setTimeout(() => {
        scrollToCurrent();
        switchMenuItem(menuItems);
      }, 200);
    });

    const outerContent = $(".specialization__list").parent();
    const innerContent = $(".specialization__list");

    outerContent.scrollLeft((innerContent.width() - outerContent.width()) / 2);

    addEventListener("hashchange", () => {
      currentpage = parseInt(document.location.hash.replace("#", ""));
    });
  }

  function scrollToCurrent() {
    if ($(window).width() >= 1024) {
      var page = $("#page-" + currentpage);
      var pageheight = page.position().top;
      animatingup = true;
      animatingdown = true;
      $("html, body").animate({ scrollTop: pageheight }, 300, function () {
        document.location.hash = currentpage;
        animatingup = false;
        animatingdown = false;
      });
    } else {
      document.location.hash = currentpage;
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }

  function switchMenuItem(menuItems) {
    menuItems.removeClass("active");
    window.location.hash = `${currentpage}`;
    $(`#menu-range`).val(currentpage);
    $(`span[data-menu-item=${currentpage}]`).addClass("active");
  }
});
