$(document).ready(function() {
    if (document.querySelector('input[type="file"]')) {
        const fileInput = document.querySelector('input[type="file"]');        

        fileInput.addEventListener('input', function (e) {
            if(fileInput.value !== "") {
                let fileName = e.target.value.split(/(\\|\/)/g).pop();
                if (fileName.length > 13) {
                    fileName = fileName.slice(0, 12).concat('...');
                }
                document.querySelector('.feedback__file span:first-child').textContent = fileName;
                console.log(fileName);
             }
        });
    }
});