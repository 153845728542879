document.addEventListener("DOMContentLoaded", function () {
  const parentSpecializations = $("a[data-spec-id]");
  const childSpecializations = $("li[data-parent-id]");

  if (parentSpecializations.length !== 0 && childSpecializations.length !== 0) {
    parentSpecializations
      .on("mouseenter", handlerIn)
      .on("mouseleave", handlerOut);

    const shuffledParentSpecs = shuffle(parentSpecializations);
    shuffledParentSpecs.css({ opacity: 0 });

    setInterval(() => {
      let i = Math.floor(Math.random() * parentSpecializations.length);
      shuffledParentSpecs[i].style.opacity = 1;
      setTimeout(() => {
        shuffledParentSpecs[i].style.opacity = 0;
      }, 1500);
    }, 2000);

    function handlerIn(e) {
      const parentId = e.target.getAttribute("data-spec-id");
      e.target.style.opacity = 1;
      Array.from(childSpecializations).map((child) => {
        if (child.getAttribute("data-parent-id") == parentId) {
          child.classList.add("active");
        }
      });
    }

    function handlerOut(e) {
      e.target.style.opacity = 0;
      childSpecializations.removeClass("active");
    }

    function shuffle(array) {
      var i = 0,
        j = 0,
        temp = null;

      for (i = array.length - 1; i > 0; i -= 1) {
        j = Math.floor(Math.random() * (i + 1));
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
  }

  $(".front").on("mousemove", function (event) {
    this.querySelectorAll("#parallax").forEach((shift) => {
      const position = shift.getAttribute("value");
      const x = (window.innerWidth - event.pageX * position) / 90;
      const y = (window.innerHeight - event.pageY * position) / 90;

      shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
  });
});
